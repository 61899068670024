import type { OptionItem, OptionsFetcher } from 'common/types';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { ArrowSvg } from 'components/svg/arrow-svg';
import { useOutsideClick } from 'hooks/use-outside-click';
import { TableSelectOptions } from 'components/table-select-options';
import { useSearch } from 'hooks/use-search';
import { PAGE_SIZE } from 'common/constants';
import { TableSelectStyled, TableSelectWrapper } from './style';

interface SelectServerDataProps {
  openTop?: boolean;
  initialValue: OptionItem | null;
  onSelect: (value: OptionItem) => void;
  disabled?: boolean;
  withoutBg?: boolean;
  validate?: boolean;
  isTouched?: boolean;
  fetcher: OptionsFetcher;
  canSetNull?: boolean;
}

export const SelectServerData: FC<SelectServerDataProps> = ({
  initialValue,
  onSelect,
  disabled,
  withoutBg,
  validate,
  isTouched,
  fetcher,
  canSetNull
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [openTop, setOpenTop] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  useOutsideClick(dropdownRef, () => {
    setShowOptions(false);
  });

  const { term, searchTerm, onChangeSearch } = useSearch();
  const [hasMoreOptions, setHasMoreOptions] = useState(true);
  const [options, setOptions] = useState<OptionItem[]>([]);
  const [limit, setLimit] = useState(PAGE_SIZE);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true);
      try {
        const options = await fetcher(`?limit=${limit}&search=${searchTerm}`);
        setOptions(options);
        setHasMoreOptions(limit <= options.length);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (showOptions) {
      fetchOptions();
    }
  }, [fetcher, limit, searchTerm, showOptions]);

  const handleSelect = (item: OptionItem) => {
    onSelect(item);
    setShowOptions(false);
    onChangeSearch('');
  };

  const toggleSelect = () => {
    setShowOptions((state) => {
      if (!state && dropdownRef.current) {
        if (dropdownRef.current) {
          const minOffset = window.innerHeight >= 700 ? 250 : 150;
          setOpenTop(
            window.innerHeight -
              dropdownRef.current.getBoundingClientRect().top <
              minOffset && window.innerHeight > 600
          );
        }
      }

      return !state;
    });
  };

  return (
    <TableSelectWrapper ref={dropdownRef}>
      <TableSelectStyled
        onClick={toggleSelect}
        $isOpen={showOptions}
        $disabled={disabled}
        $withoutBg={withoutBg}
        $invalid={validate && !initialValue?.title}
        $isTouched={isTouched}
      >
        {initialValue?.title || '-'}
        {!disabled && <ArrowSvg />}
      </TableSelectStyled>
      {showOptions && !disabled && (
        <TableSelectOptions
          options={canSetNull ? [{ id: '', title: '' }, ...options] : options}
          onSelect={handleSelect}
          searchTerm={term}
          onChangeSearch={onChangeSearch}
          openTop={openTop}
          setLimit={setLimit}
          hasMoreOptions={hasMoreOptions}
          isLoading={isLoading}
        />
      )}
    </TableSelectWrapper>
  );
};
