import type { ReactNode } from 'react';
import { UpdatedAtStyled } from './style';

interface UpdatedAtProps {
  children: ReactNode;
  absoluteAlign?: boolean;
  show?: boolean;
}
export const UpdatedAt = ({
  children,
  absoluteAlign,
  show = true
}: UpdatedAtProps) => (
  <UpdatedAtStyled $show={show} $absoluteAlign={absoluteAlign}>
    Оновлено <span>{children}</span>
  </UpdatedAtStyled>
);
