import { updateFleetTiresReplacementsApi } from 'api/fleet-tires-replacements';
import type { ID } from 'common/types';
import { CellInput } from 'components/cell-input';
import { EditableCell } from 'components/editable-cell';
import type { FleetTire } from 'models/fleet-tires-replacements';
import { useState } from 'react';
import { updateFleetReplacement } from 'store/fleet-tires-replacements/reducer';
import { useAppDispatch } from 'store/store';

interface RecipientProps {
  vehicleId: ID;
  currentTire: FleetTire;
  initialValue: string;
  id: ID;
  disabled?: boolean;
}

export const Recipient = ({
  currentTire,
  initialValue,
  disabled,
  vehicleId
}: RecipientProps) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(initialValue);

  const handleChange = (value: string) => {
    setValue(value);
  };

  const handleBlur = () => {
    if (value !== initialValue) {
      dispatch(
        updateFleetReplacement({
          vehicleId,
          tire: { ...currentTire, recipient_data: value }
        })
      );
      updateFleetTiresReplacementsApi({
        id: vehicleId,
        body: {
          tire_data: { id: currentTire.id, recipient_data: value }
        }
      }).catch(console.log);
    }
  };

  return (
    <EditableCell withOutPaddingTop disabled={disabled}>
      <CellInput
        onBlur={handleBlur}
        disabled={disabled}
        value={value}
        setValue={handleChange}
      />
    </EditableCell>
  );
};
