import styled, { css } from 'styled-components';

const absoluteAlign = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  @media (max-width: ${(p) => p.theme.breakpoints.lg}) {
    position: static;
    transform: unset;
  }
`;

export const UpdatedAtStyled = styled.div<{
  $absoluteAlign?: boolean;
  $show?: boolean;
}>`
  color: ${(p) => p.theme.colors.secondaryDark};
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  opacity: ${(p) => (p.$show ? 1 : 0)};
  span {
    color: ${(p) => p.theme.colors.primary};
  }
  ${(p) => (p.$absoluteAlign ? absoluteAlign : null)}
`;
