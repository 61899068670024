import { DataTable } from 'components/data-table';
import { HeaderBtn } from 'components/header-btn';
import { Navbar } from 'components/navbar';
import { Search } from 'components/search';
import { Subheader } from 'components/subheader';
import { useScrollHeight } from 'hooks/use-scroll-height';
import { useCallback, useEffect, useRef } from 'react';
import { groupeEntities } from 'helpers/groupe-entities';
import { useAppDispatch, useTypedSelector } from 'store/store';
import type { TiresReplacements } from 'models/tires-replacements';
import { updateReplacement } from 'store/tires-replacements/reducer';
import { CheckBox } from 'components/checkbox';
import {
  getTiresReplacementsAction,
  updateTiresReplacementsAction
} from 'store/tires-replacements/actions';
import { SubheaderActions } from 'routes/users/style';
import { PrintSvg } from 'components/svg/print-svg';
import { EditableCell } from 'components/editable-cell';
import {
  getTiresReplacementsApi,
  printLabelsApi
} from 'api/tires-replacements';
import { dateTableFormat, dateToHeaderFormat } from 'helpers/date';
import { useBeforeUnload } from 'hooks/use-before-unload';
import { AppRoute } from 'common/enums/app/app-route';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NoData } from 'components/no-data';
import { TableSelect } from 'components/table-select';
import { Defects, PAGE_SIZE } from 'common/constants';
import type { ID, OptionItem } from 'common/types';
import { toast } from 'react-toastify';
import { getApiError } from 'helpers/get-api-error';
import { UpdatedAt } from 'components/updated-at';
import { Loader } from 'components/loader';
import { getServiceAllUpdatesAction } from 'store/service-all-updates/actions';
import { updateNotif } from 'store/notifications/reducer';
import type { ReplacementsTable } from './data';
import { tableHeaders } from './data';
import { ServiceComment } from './service-comment';
import { TreadDepth } from './tread-depth';

export const TiresReplacementsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [searchParams] = useSearchParams();
  const { scrollHeight } = useScrollHeight(tableRef);
  const { replacements, isLoading } = useTypedSelector(
    (state) => state.tiresReplacements
  );
  const { serviceUpdates } = useTypedSelector((state) => state.serviceUpdates);

  useBeforeUnload(
    replacements.results.filter((item) => item.confirmed).length !==
      replacements.results.length
  );

  useEffect(() => {
    const search = searchParams.get('search');
    const page = searchParams.get('page');
    const queryString = `page=${page || 1}`;
    dispatch(
      getTiresReplacementsAction(
        search ? `?search=${search}&${queryString}` : `?${queryString}`
      )
    );
    dispatch(updateNotif({ serviceReplacements: false }));
    dispatch(getServiceAllUpdatesAction());
  }, [dispatch, searchParams]);

  const handlePrintLabels = () => {
    const ids = replacements.results
      .filter((item) => item.print)
      .map((item) => item.id);

    if (!ids.length) {
      toast('Будь ласка, виберіть хоча б один елемент для друку', {
        type: 'info'
      });

      return;
    }
    printLabelsApi(ids)
      .then((res) => {
        const existsIframes = document.querySelectorAll('iframe');
        existsIframes.forEach((item) => {
          item.remove();
        });
        const url = window.URL.createObjectURL(res as Blob);
        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.contentWindow?.print();
      })
      .catch(console.log);
  };

  const handleConfirmed = (item: TiresReplacements) => {
    dispatch(
      updateTiresReplacementsAction({
        id: item.id,
        body: {
          service_comment: item.service_comment,
          confirmed: true,
          defect: item.defect.id,
          tread_depth: item.tread_depth
        }
      })
    )
      .unwrap()
      .catch((err) => toast(getApiError(err), { type: 'error' }));
  };

  const handleDefectSelect = useCallback(
    (id: ID, option: OptionItem, item: TiresReplacements) => {
      dispatch(
        updateTiresReplacementsAction({
          id,
          body: {
            defect: option.id,
            service_comment: item.service_comment,
            tread_depth: item.tread_depth
          }
        })
      );
    },
    [dispatch]
  );

  const transformToTableFormat = (movements: TiresReplacements[]) =>
    movements.map((item) => ({
      ...item,
      tire_location: item.isHeader ? '' : item.tire_location,
      season: item.isHeader ? '' : item.season,
      size: item.isHeader ? '' : item.size,
      diameter: item.isHeader ? '' : item.diameter,
      action: item.isHeader ? '' : item.action,
      tread_depth: item.isHeader ? (
        ''
      ) : (
        <TreadDepth
          disabled={
            item.action.toLowerCase() === 'встановити' || item.confirmed
          }
          value={item.tread_depth}
          id={item.id}
        />
      ),
      contact_name: item.isHeader ? '' : item.contact_name,
      contact_phone: item.isHeader ? '' : item.contact_phone,
      tire_model: item.isHeader ? '' : item.tire_model,
      vehicle_model: item.isHeader ? item.vehicle_model : '',
      vehicle_plate: item.isHeader ? item.vehicle_plate : '',
      scheduled_replacement_date: item.isHeader
        ? ''
        : dateTableFormat(item.scheduled_replacement_date),
      service_comment: item.isHeader ? (
        ''
      ) : (
        <ServiceComment
          disabled={item.confirmed}
          value={item.service_comment}
          id={item.id}
        />
      ),
      defect: item.isHeader ? (
        ''
      ) : (
        <TableSelect
          disabled={
            item.action.toLowerCase() === 'встановити' || item.confirmed
          }
          options={Defects}
          initialValue={item.defect}
          onSelect={(value) => handleDefectSelect(item.id, value, item)}
        />
      ),
      confirmed: item.isHeader ? (
        ''
      ) : (
        <EditableCell disabled={item.confirmed}>
          <CheckBox
            disabled={item.confirmed}
            checked={item.confirmed}
            setChecked={() => handleConfirmed(item)}
          />
        </EditableCell>
      ),
      doc_number: item.isHeader ? item.doc_number : '',
      print: item.isHeader ? (
        ''
      ) : (
        <EditableCell>
          <CheckBox
            checked={!!item.print}
            setChecked={(checked) =>
              dispatch(updateReplacement({ ...item, print: checked }))
            }
          />
        </EditableCell>
      )
    }));

  return (
    <>
      <Navbar>
        <Search
          fetcher={getTiresReplacementsApi}
          rootPath={AppRoute.TIRES_REPLACEMENTS}
        />
      </Navbar>
      <Subheader title="Зміна шин">
        <UpdatedAt
          show={
            !!serviceUpdates.results.length && !!replacements.results.length
          }
        >
          {!!serviceUpdates.results.length &&
            !!replacements.results.length &&
            dateToHeaderFormat(
              serviceUpdates.results[0].service_replacements_table
            )}
        </UpdatedAt>
        <SubheaderActions>
          <HeaderBtn icon={<PrintSvg />} onClick={handlePrintLabels}>
            Друк маркування
          </HeaderBtn>
          <HeaderBtn onClick={() => navigate(AppRoute.REPLACEMENTS_HISTORY)}>
            Переглянути історію
          </HeaderBtn>
        </SubheaderActions>
      </Subheader>
      <div ref={tableRef} style={{ position: 'relative' }}>
        {replacements.results.length ? (
          <DataTable<ReplacementsTable>
            headers={tableHeaders}
            data={transformToTableFormat(groupeEntities(replacements.results))}
            checkedList={[]}
            setCheckedList={() => {}}
            actions={[]}
            withOutCheck
            customHeight={
              replacements.count >= PAGE_SIZE ? scrollHeight - 64 : scrollHeight
            }
            count={replacements.count}
            isFetching={isLoading}
          />
        ) : isLoading ? (
          <Loader isBig place={{ left: 'calc(50% - 16px)', top: '100px' }} />
        ) : (
          <NoData height={`${scrollHeight}px`} />
        )}
      </div>
    </>
  );
};
