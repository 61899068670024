import styled, { css } from 'styled-components';

const styleWithPagination = css`
  width: fit-content;
  right: 0;

  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    right: 50%;
    transform: translateX(50%);
    padding-right: 0;
  }
`;

const styleWithOutPagination = css`
  border-top: 1px solid ${(p) => p.theme.colors.stroke};
  background: #fffbfc;
  width: calc(100vw - 232px);
  left: 232px;
  @media (max-width: ${(p) => p.theme.breakpoints.xs}) {
    width: 100%;
    left: 0;
    padding-right: 20px;
  }
`;

export const ConfirmPanelStyled = styled.div<{ $hasPagination?: boolean }>`
  position: fixed;
  padding-right: 20px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 64px;
  transition: all 0.4s;
  z-index: 11;
  ${(p) => (p.$hasPagination ? styleWithPagination : styleWithOutPagination)}
  button {
    font-weight: 400;
    white-space: nowrap;
    &:disabled {
      color: #b7b7b7;
      background: #e0e0e0;
    }
  }
`;
