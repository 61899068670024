import { StringCutter } from 'components/string-cutter';
import { CellInputStyled } from './style';

interface CellInputProps {
  value: string;
  setValue: (value: string) => void;
  type?: 'text' | 'number';
  disabled?: boolean;
  onBlur?: () => void;
}

export const CellInput = ({
  value,
  setValue,
  type = 'text',
  onBlur,
  disabled
}: CellInputProps) =>
  disabled ? (
    <StringCutter $lines="8">{value}</StringCutter>
  ) : (
    <CellInputStyled
      value={value}
      onChange={(e) => setValue(e.target.value)}
      type={type}
      disabled={disabled}
      onBlur={onBlur}
    />
  );
