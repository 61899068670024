import type { OptionItem } from 'common/types';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useRef, useState, type FC } from 'react';
import { Loader } from 'components/loader';
import { useSetInfiniteScrollLimit } from 'hooks/use-set-infinite-scroll-limit';
import { SearchSvg } from 'components/svg/search-svg';
import { CloseSvg } from 'components/svg/close-svg';
import {
  CloseIcon,
  NoDataText,
  OptionsItem,
  SearchIcon,
  SearchInput,
  SearchStyled,
  SearchWrapper,
  TableSelectOptionsStyled
} from './style';

interface TableSelectOptionsProps {
  options: OptionItem[];
  onSelect: (value: OptionItem) => void;
  openTop?: boolean;
  hasMoreOptions?: boolean;
  setLimit: Dispatch<SetStateAction<number>>;
  searchTerm: string;
  onChangeSearch: (value: string) => void;
  isLoading: boolean;
  isFilter?: boolean;
}

export const TableSelectOptions: FC<TableSelectOptionsProps> = ({
  options,
  onSelect,
  openTop,
  hasMoreOptions,
  setLimit,
  searchTerm,
  onChangeSearch,
  isLoading,
  isFilter
}) => {
  const loaderRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);
  useSetInfiniteScrollLimit({ loaderRef, setLimit });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <TableSelectOptionsStyled $isFilter={isFilter} $openTop={openTop}>
      <SearchWrapper>
        <SearchStyled $isActive={focus}>
          <SearchInput
            ref={inputRef}
            value={searchTerm}
            onChange={(e) => onChangeSearch(e.target.value)}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          {isLoading ? (
            <Loader place={{ top: 'calc(50% - 8px)', right: '8px' }} />
          ) : searchTerm ? (
            <CloseIcon onClick={() => onChangeSearch('')}>
              <CloseSvg />
            </CloseIcon>
          ) : (
            <SearchIcon>
              <SearchSvg />
            </SearchIcon>
          )}
        </SearchStyled>
      </SearchWrapper>
      {options.map((item) => (
        <OptionsItem key={item.id} onClick={() => onSelect(item)}>
          {item.title || '-'}
        </OptionsItem>
      ))}
      {!options.length && <NoDataText>Нічого не знайдено</NoDataText>}
      <div
        style={{
          display:
            !hasMoreOptions ||
            !options.length ||
            (options.length === 1 && !options[0].id)
              ? 'none'
              : 'block'
        }}
        ref={loaderRef}
      >
        <Loader position="static" />
      </div>
    </TableSelectOptionsStyled>
  );
};
